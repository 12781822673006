'use es6';

import styled from 'foundations-theming/utils';
import AvatarWrapper from './AvatarWrapper';
export default styled(AvatarWrapper).withConfig({
  displayName: "AvatarWrapperOverlay",
  componentId: "ent5po-0"
})(["& .overlay-content{align-items:center;background:", ";color:", ";cursor:pointer;display:flex;font-family:sans-serif;height:0;hyphens:auto;justify-content:center;left:0;opacity:0;overflow:visible;padding:calc(50%);position:absolute;text-align:center;top:0;transition:opacity 300ms ease;width:0;}&:hover .overlay-content,&.hover-test .overlay-content{opacity:0.85;}"], ({
  theme
}) => theme.color['do-not-use-slinky'], ({
  theme
}) => theme.color['do-not-use-olaf']);